'use client'

import React from "react";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Typography, Grid } from '@mui/material';
import Image from "next/image";

export const UeberMichSection = ({ data }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <motion.section
      className="section-large"
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 1 }}
    >
      <div>
        <div className="section-header">
          <div className="section-title">
            <Typography variant="h3" component="h2" gutterBottom align="center">
              {data.Titel}
            </Typography>
          </div>
        </div>
        <div className="section-body">
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              <div style={{ borderRadius: '15px', overflow: 'hidden', display: 'inline-block' }}>
                <Image src={data.Bild} alt="Lea Stiffler" width={550} height={550} />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography color="text.secondary" variant="body1" component='span'>
                {data.Intro}
              </Typography>
              <br />
              <br />
              <Typography variant="h6" component="h3" gutterBottom>
                Werdegang
              </Typography>
              {data.Werdegang.map((ausbildung, index) => (
                <div key={index}>
                  <Typography variant="subtitle2" component="h4" gutterBottom>
                    {ausbildung.Titel}
                  </Typography>
                  <Typography variant="caption" color="text.secondary" paragraph>
                    {ausbildung.Text}
                  </Typography>
                </div>
              ))}
              <br />
              <Typography variant="h6" component="h3" gutterBottom>
                Weiterbildungen
              </Typography>
              {data.Weiterbildungen.map((ausbildung, index) => (
                <div key={index}>
                  <Typography variant="subtitle2" component="h4" gutterBottom>
                    {ausbildung.Titel}
                  </Typography>
                  <Typography variant="caption" color="text.secondary" paragraph>
                    {ausbildung.Text}
                  </Typography>
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
    </motion.section>
  );
};
