import React from 'react';
import RootLayout from "@/app/layout";
import { HeaderSection } from '@/app/components/Sections/HeaderSection';
import { UeberMichSection } from '@/app/components/Sections/UeberMichSection';
import { AngebotSection } from '@/app/components/Sections/AngebotSection';
import { PhilosophieSection } from '@/app/components/Sections/PhilosophieSection';
import data from '../data/data.json';
import Head from 'next/head';

export const metadata = {
  title: 'Hebamme Lea Stiffler',
  description: 'Biete professionelle Hebammenbetreuung in Malans, Graubünden,  Schweiz. Entdecken Sie individuell abgestimmte Angebote rund um Schwangerschaft, Geburt und Wochenbett.',
  keywords: 'Hebamme Malans, Schwangerschaftsbetreuung, Geburtsvorbereitung, Wochenbettbetreuung, individuelle Beratung, Schwangerschaft, Geburt, Betreuung nach der Geburt, Malans Schweiz, Graubünden',
  canonicalUrl: 'https://hebamme-leastiffler.ch'
}


export async function getStaticProps() {
  return { props: { data } }
}

export default function Home({ data }) {
  const { Philosophie, Header, ÜberMich, Angebot } = data;

  return (
    <RootLayout>
      <Head>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <link rel="canonical" href={metadata.canonicalUrl} />
      </Head>
      <HeaderSection data={Header} />
      <UeberMichSection data={ÜberMich} />
      <PhilosophieSection data={Philosophie} />
      <AngebotSection data={Angebot} />
    </RootLayout>
  );
}
