'use client'

import React from "react";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Grid, CardContent, Container, Link, Typography } from '@mui/material';
import Image from "next/image";

export const AngebotSection = ({ data }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <motion.section
      className="section-large"
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 1 }}
    >
      <div className="section-header">
        <div className="section-title">
          <Typography variant="h3" component="h2" gutterBottom align="center">
            {data.Titel}
          </Typography>
        </div>
      </div>
      <div className="section-body">
        <Container maxWidth="md">
          {data.Angebote && data.Angebote.map((angebot, index) => (
            <div key={index} className="text-centered">
              <Typography variant="h4" component="h3" color="primary" gutterBottom>
                {angebot.Titel}
              </Typography>
              <CardContent>
                <Link href={angebot.Link} underline="hover">
                  <Grid container spacing={4} mb={10} direction={index % 2 === 0 ? 'row' : 'row-reverse'} alignItems="center">
                    <Grid item xs={12} md={5}>
                      <div style={{ borderRadius: '15px', overflow: 'hidden', display: 'inline-block' }}>
                        <Image src={angebot.Bild} alt={angebot.Titel} width={300} height={550} />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Typography variant="body1" color="text.secondary" sx={{
                        '@media (max-width: 600px)': {
                          display: 'none',
                        },
                      }} paragraph>
                        {angebot.Beschreibung}
                      </Typography>
                      Details
                    </Grid>
                  </Grid>
                </Link>
              </CardContent>
            </div>
          ))}
        </Container>
      </div>
    </motion.section>
  );
};
