'use client'

import React from "react";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Box, Typography } from '@mui/material';
import Image from "next/image";

export const HeaderSection = ({ data }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  return (
    <motion.section
      className="header-section"
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 0.2 }}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 5rem)', marginTop: '3rem', marginBottom: '3rem' }}
    >
      <Box sx={{ width: 'fit-content', height: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem' }}>
        <Image src={data.Bild} width={1000} height={1000} alt="Logo Hebamme Lea Stiffler"/>
        <Typography variant="h3" component="h1" gutterBottom align="center" hidden>
          {data.Titel}
        </Typography>
      </Box>
    </motion.section>
  );
};
