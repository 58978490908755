'use client'

import React from "react";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Typography } from '@mui/material';

export const PhilosophieSection = ({ data }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <motion.section
      className="section-medium"
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 1 }}
    >
      <div>
        <div className="section-header">
          <div className="section-title">
            <Typography variant="h3" component="h2" gutterBottom align="center">
              {data.Titel}
            </Typography>
          </div>
        </div>
        <div className="section-body text-centered">
          <Typography color="text.secondary" variant="body1" component='span'>
            {data.Text}
          </Typography>
        </div>
      </div>
    </motion.section>
  );
};
